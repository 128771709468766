.anim_gradient {
	background: linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(255,225,203,1) 100%);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100svh;
    overflow: hidden;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}